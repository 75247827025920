import React from "react";
import abouticon1 from "../Images/cusp-about-icon1.webp";
import abouticon2 from "../Images/cusp-about-icon2.webp";
import abouticon from "../Images/cusp about icon.webp";
import mission from "../Images/mission.webp";
import vision from "../Images/vision.webp";

const KeyObjects2 = () => {
  const data = [
    {
      heading: "Promote Solar Adoption",
      img: abouticon1,
    },
    {
      heading: "Simplify the Solar Buying Process",
      img: abouticon,
    },
    {
      heading: "Educate and Empower Users",
      img: abouticon2,
    },
    {
      heading: "Foster long term solar partnership",
      img: abouticon2,
    },
    {
      heading: "Enhance Accessibility",
      img: abouticon2,
    },
    {
      heading: "Drive Transparency",
      img: abouticon2,
    },
  ];
  const data1 = [
    {
      id: 1,
      img: mission,
      heading: "Mission",
      content: [
        "We are here to empower individuals and businesses to make the switch to solar energy with ease, providing cost-effective and sustainable solutions that drive a greener future. Committed to delivering innovative, high-quality solar products and services that meet the diverse needs of our customers we fostering environmental responsibility.",
      ],
    },
    {
      id: 2,
      img: vision,
      heading: "Vision",
      content: [
        "To be a leading force in the global transition to clean, renewable energy by making solar power accessible, affordable, and efficient for everyone. We envision a future where every home, business, and community is powered by solar, contributing to a sustainable planet for generations to come",
      ],
    },
  ];
  return (
    <div>
      <div className="bg-[#FFF4D9] px-[4%] py-[4%] ">
        <div className="text-center">
          <h2 className="text-[#196625]  font-[700] text-[2.3rem]">
            Our Objectives
          </h2>
          <h4 className="text-[1.6rem] font-[600] text-[#004A9C] ad max-[600px]:text-[1.2rem]">
            Driving Sustainability, Empowering Customers, Supporting Service
            Providers
          </h4>{" "}
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-8 mt-11">
          {data.map((item, index) => (
            <div
              key={index}
              className="p-4 flex flex-col items-center shadow-md"
            >
              <div className="mx-auto">
                <img
                  className="w-11 h-11 object-cover rounded-t-lg "
                  src={item.img}
                  alt={item.img}
                />
              </div>
              <div className="mt-4 text-center">
                <h3 className="text-lg font-600 text-gray-900">
                  {item.heading}
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/*---vision and mission---*/}
      <div className="flex gap-5 items-stretch justify-center mx-[4%] max-[1000px]:flex-col mt-[3%] mb-[1%] min-h-[20vh]">
        {data1.map((item) => (
          <div key={item.id} className="mb-9 flex-1 flex">
            {" "}
            <div className="flex gap-3 max-[600px]:flex-col max-[600px]:items-center max-[600px]:justify-center  bg-[#E1F5F1] p-6 rounded-2xl h-full">
              <img src={item.img} className="w-24 h-24 mt-3 " alt={item.img} />
              <div className="">
                <h2 className="text-[#253745] text-[1.6rem] font-[700] ad mb-3 ml-6 max-[600px]:text-center">
                  {item.heading}
                </h2>
                {item.content.map((text, idx) => (
                  <div
                    key={idx}
                    className="ml-6 text-[1rem] font-[500] text-gray-600"
                  >
                    {text}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default KeyObjects2;
