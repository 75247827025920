import React from 'react'
import solarcosultation1 from "./../Images/Services_consult-1.png"
import solarconsultation2 from "./../Images/Services_consult-2.png"
import installation1 from "./../Images/Services_Installation_1.png"
import installation2 from "./../Images/Services_Installation_2.png"
import installation3 from "./../Images/Services_Installation_3.png"
import financingimg from "./../Images/services_finance.png"
import maintenance from "./../Images/services_MaintananceBanner.png"
import whychooseus1 from "./../Images/services_whyChoose1.png"
import whychooseus2 from "./../Images/services_whyChoose2.png"

const Services = () => {
  return (
    <div>
        {/*---services-bg---*/}
        <div className="services-bg flex items-end justify-center relative h-[60vh] max-[600px]:h-[50vh]">
        <div className="rounded-md bg-[rgba(13,13,13,0.6)] p-4 w-auto text-center absolute max-[600px]:mx-3 bottom-4">
          <h2 className="text-4xl text-white font-semibold pb-3 max-[600px]:text-2xl ">
          End-to-End Solar Solutions for Every Need!
          </h2>
          <a href="/contactus">
            {" "}
            <button className="bg-orange-500 cursor-pointer text-white px-4 py-2 rounded-md border border-white hover:bg-orange-400">
              Get a Free Solar Quote
            </button>
          </a>
        </div>
      </div>
      {/*---solar consultation----*/}
      <div className="flex flex-col lg:flex-row items-center justify-center px-10 gap-10 max-[1000px]:my-[2rem] my-5 relative min-h-[40vh]">
      <div className="lg:w-[60%] flex gap-11  max-[600px]:flex-col w-full ">
          <img
            className="w-1/2 rounded-xl   max-[600px]:w-full relative top-[5rem] mb-[5rem]"
            src={solarcosultation1}
            alt="Solar panel water pump"
          />
          <img
            className="w-1/2 rounded-xl border-green-500  relative top-8 mb-[5rem]  max-[600px]:w-full"
            src={solarconsultation2}
            alt="Solar panel field"
          />
        </div>
      
        <div className="lg:w-[40%]">
          <h2 className="text-[2rem] text-[#FD8000] font-semibold">
          Solar Consultation
          </h2>
          <p className="text-[1rem] text-[#333] leading-[1.6]">
          Expert guidance to help you choose the best solar solution for your energy requirements and budget.
          </p>
        </div>
     </div>
     {/*----Installation----*/}
     <div className="installation-section_bg">
      <div className="installation-section">
          <div className="installation-overlay">
            <h2>Installation</h2>
            <p>
            Expert guidance to help you choose the best solar solution for your energy requirements and budget.
            </p>
          </div>
          <div className="installation-images">
            <img src={installation1} alt="Inverter row" />
            <img src={installation2} alt="Inverter unit" />
            <img src={installation3} alt="Wall-mounted inverter" />
          </div>
        </div>
     </div>
    {/*--financing options----*/}
    <div className="financing flex max-[1000px]:flex-col gap-3 items-center bg-[#F1EADA]">
    <div className="financing-image w-[1/2] max-[600px]:w-full">
          <img src={financingimg} alt="Solar battery with panel" />
        </div>
        <div className="w-[1/2] max-[600px]:w-full pl-[2rem]">
          <h2 className="text-[2rem] text-[#f7941d] mb-[0.8rem] font-[600] leading-[1]">
          Financing Options
          </h2>
          <p>
          Flexible financing options to make going solar easy and affordable for everyone.
          </p>
        </div>
        </div>
      {/*---Maintenance-----*/}
      <div>
        <div className="bg-[#ECECEF] py-[4%] text-center">
          <h2 className="text-[2rem] text-[#f7941d] mb-[0.5] font-semibold">
          Maintenance
          </h2>
          <p className="text-[1rem] leading-[1.6] text-[#333] ">
          Regular maintenance and support to ensure your solar system operates at peak efficiency.
          </p>
        </div>
        
          <div className="">
            <img
              src={maintenance}
              className=""
              alt="maintenance-img"
            />
          </div>
         </div>
         {/*----why choose us----*/}
         <div className="flex flex-col bg-[#FD80000D] lg:flex-row items-center justify-center py-9 px-10 gap-10 max-[1000px]:my-[2rem] min-h-[40vh]">
         <div className="lg:w-[40%]">
          <h2 className="text-[2rem] text-[#FD8000] font-semibold mb-[1rem]">
          Why Choose Us
          </h2>
          <p className="text-[1rem] text-[#333] leading-[1.6]">
          "Expertise, Quality, and Commitment – Your trusted partner for sustainable energy solutions."     </p>
        </div>
     
         <div className="lg:w-[60%] flex gap-11  max-[600px]:flex-col w-full ">
          <img
            className="w-1/2 rounded-xl   max-[600px]:w-full  border border-[#3CAB90]"
            src={whychooseus1}
            alt="Solar panel water pump"
          />
          <img
            className="w-1/2 rounded-xl  max-[600px]:w-full border border-[#3CAB90]"
            src={whychooseus2}
            alt="Solar panel field"
          />
        </div>
      </div>
     {/*--ready to go solar----*/}
     <div className="cta flex justify-between  max-[1000px]:flex-col mb-1">
        <div className="">
          <h2 className="text-[2rem] mb-[0.5rem] font-bold leading-[1] text-[#fff]">
            Ready to go solar?
          </h2>
          <p className="text-[1.125rem] leading-[1.6] text-[#fff]">
            Contact us to explore customized solutions that meet your needs.
          </p>
        </div>
        <div className="cta-button">
          <a href="/contactus">
            <button className="bg-orange-500 max-[1000px]:mt-[1rem] cursor-pointer text-white px-4 py-2 rounded-md border border-white hover:bg-orange-400">
              Contact Us
            </button>
          </a>
        </div>
      </div>
   </div>
)
}

export default Services