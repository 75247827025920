import React from 'react'
import productsbg from "../Images/products_bg.png"
import solarpanel1 from "../Images/solarPanel1.png"
import solarpanel2 from "../Images/solarPanel2.png"
import inverter1 from "../Images/inverter1.png"
import inverter2 from "../Images/inverter2.png"
import inverter3 from "../Images/inverter3.png"
import batteries from "../Images/batteries_img.png"
import accessory1 from "../Images/accessory_img1.png"
import accessory2 from "../Images/accessory_img2.png"
const Products = () => {
  return (
    <div className="products_page">
      {/* Hero Section */}
      <section className="relative heroSection">
        <img
          src={productsbg}// Make sure this image exists in /public/images
          alt="Solar House"
          width={1920}
          height={1080}
          className="w-full h-[60vh] object-cover"
        />
        <div className="overlay absolute inset-0 flex flex-col justify-center items-center bg-black/50 text-white w-max m-auto h-max p-3">
          <h1 className="text-3xl md:text-4xl font-semibold text-center mb-6">
            Power Your Future with Solar!
          </h1>
          <a href="/contactus"> <button className="bg-orange-500 cursor-pointer text-white px-4 py-2 rounded-md border border-white hover:bg-orange-400">
            Get a Free Solar Quote →
          </button></a>
        </div>
      </section>

      {/* Info Section */}
      <div className="info-section">
        <div className="info-text">
          <h2>Solar Panels</h2>
          <p>
            Our network of verified installers provide high-efficiency solar panels for residential,
            commercial, and industrial use. Choose from monocrystalline, polycrystalline, or thin-film
            panels to suit your energy needs.
          </p>
        </div>
        <div className="info-images">
          <img className="" src={solarpanel1} alt="Solar panel water pump" />
          <img className="" src={solarpanel2} alt="Solar panel field" />
        </div>
      </div>

     <div className="inverters-section_bg">
      <section className="inverters-section">
          <div className="inverters-overlay">
            <h2>Inverters</h2>
            <p>
              Reliable and durable solar inverters sourced from trusted brands, ensuring seamless power
              conversion for your energy needs.
            </p>
          </div>
          <div className="inverters-images">
            <img src={inverter1} alt="Inverter row" />
            <img src={inverter2} alt="Inverter unit" />
            <img src={inverter3} alt="Wall-mounted inverter" />
          </div>
        </section>
     </div>

      <section className="batteries-section">
        <div className="batteries-text">
          <h2>Batteries</h2>
          <p>
            High-quality solar batteries provided by top-rated manufacturers, designed for efficient
            energy storage and an uninterrupted power supply.
          </p>
        </div>
        <div className="batteries-image">
          <img src={batteries} alt="Solar battery with panel" />
        </div>
      </section>

      <section className="solarAccessories-section">
        <div className="solarAccessories-text">
          <h2>Solar Accessories</h2>
          <p>
            Premium solar accessories, including mounting structures, cables, and charge controllers, supplied by trusted professionals for optimal installation
          </p>
        </div>
        <div className="accessories-section">
            <div className="accessories-left">
              <img src={accessory1} alt="Solar Accessories Left" />
            </div>
            <div className="accessories-right">
              <img src={accessory2} alt="Solar Accessories Right" />
            </div>
          </div>
      </section>

      <section className="cta-section mb-1">
        <div className="cta-content">
          <div className="cta-text">
            <h2>Ready to go solar?</h2>
            <p>Contact us to explore customized solutions that meet your needs.</p>
          </div>
          <div className="cta-button">
          <a href="/contactus"> 
          <button className="bg-orange-500 cursor-pointer text-white px-4 py-2 rounded-md border border-white hover:bg-orange-400">Contact Us</button>
          </a>
          </div>
        </div>
      </section>
    </div>
  
  )
}

export default Products