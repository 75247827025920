import React from "react";
import solarpanel1 from "./../Images/solarPanel1.png";
import solarpanel2 from "./../Images/solarPanel2.png";
import inverter1 from "./../Images/inverter1.png";
import inverter2 from "./../Images/inverter2.png";
import inverter3 from "./../Images/inverter3.png";
import batteries from "./../Images/batteries_img.png";
import accessory1 from "./../Images/accessory_img1.png";
import accessory2 from "./../Images/accessory_img2.png";
const Products1 = () => {
  return (
    <div>
      {/*---products-bg----*/}
      <div className="products-bg flex items-end justify-center relative h-[60vh] max-[600px]:h-[50vh]">
        <div className="rounded-md bg-[rgba(13,13,13,0.6)] p-4 w-auto text-center absolute max-[600px]:mx-3 bottom-4">
          <h2 className="text-4xl text-white font-semibold pb-3 max-[600px]:text-2xl ">
            Power Your Future with Solar!
          </h2>
          <a href="/contactus">
            {" "}
            <button className="bg-orange-500 cursor-pointer text-white px-4 py-2 rounded-md border border-white hover:bg-orange-400">
              Get a Free Solar Quote
            </button>
          </a>
        </div>
      </div>
      {/*----solar panels----*/}
      <div className="flex flex-col lg:flex-row items-center justify-center px-10 gap-10 max-[1000px]:my-[2rem] relative min-h-[40vh]">
        <div className="lg:w-[40%]">
          <h2 className="text-[2rem] text-[#FD8000] font-semibold">
            Solar Panels
          </h2>
          <p className="text-[1rem] text-[#333] leading-[1.6]">
            Our network of verified installers provides high-efficiency solar
            panels for residential, commercial, and industrial use. Choose from
            monocrystalline, polycrystalline, or thin-film panels to suit your
            energy needs.
          </p>
        </div>

        <div className="lg:w-[60%] flex gap-11  max-[600px]:flex-col w-full ">
          <img
            className="w-1/2 rounded-xl   max-[600px]:w-full relative top-[5rem] mb-[5rem]"
            src={solarpanel1}
            alt="Solar panel water pump"
          />
          <img
            className="w-1/2 rounded-xl border-green-500  relative top-8 mb-[5rem]  max-[600px]:w-full"
            src={solarpanel2}
            alt="Solar panel field"
          />
        </div>
      </div>
      {/*---Inverters----*/}
      <div className="inverters-section_bg">
      <section className="inverters-section">
          <div className="inverters-overlay">
            <h2>Inverters</h2>
            <p>
              Reliable and durable solar inverters sourced from trusted brands, ensuring seamless power
              conversion for your energy needs.
            </p>
          </div>
          <div className="inverters-images">
            <img src={inverter1} alt="Inverter row" />
            <img src={inverter2} alt="Inverter unit" />
            <img src={inverter3} alt="Wall-mounted inverter" />
          </div>
        </section>
     </div>

      {/*--Batteries----*/}

      <div className="batteries flex max-[1000px]:flex-col gap-3 items-center bg-[#F9F6EF]">
        <div className="p-[2rem] flex-[1.5]">
          <h2 className="text-[2rem] text-[#f7941d] mb-[0.5rem] font-[600] leading-[1]">
            Batteries
          </h2>
          <p>
            High-quality solar batteries provided by top-rated manufacturers,
            designed for efficient energy storage and an uninterrupted power
            supply.
          </p>
        </div>
        <div className="batteries-image flex-[1]">
          <img src={batteries} alt="Solar battery with panel" />
        </div>
      </div>

      {/*--Solar accessories----*/}
      <div>
        <div className="bg-[#ECECEF] py-[4%] text-center">
          <h2 className="text-[2rem] text-[#f7941d] mb-[0.5] font-semibold">
            Solar Accessories
          </h2>
          <p className="text-[1rem] leading-[1.6] text-[#333] ">
            Premium solar accessories, including mounting structures, cables,
            and charge controllers, supplied by trusted professionals for
            optimal installation
          </p>
        </div>
        <div className="flex items-center justify-center flex-wrap gap-6 max-[1000px]:flex-col  max-[1000px]:items-center">
          <div className="flex justify-center ">
            <img
              src={accessory1}
              className="w-[70%]"
              alt="Solar Accessories Left"
            />
          </div>
          <div className="flex justify-center">
            <img
              src={accessory2}
              className="w-[70%]"
              alt="Solar Accessories Right"
            />
          </div>
        </div>
      </div>
      {/*--ready to go solar----*/}
      <div className="cta flex justify-between  max-[1000px]:flex-col mb-1">
        <div className="">
          <h2 className="text-[2rem] mb-[0.5rem] font-bold leading-[1] text-[#fff]">
            Ready to go solar?
          </h2>
          <p className="text-[1.125rem] leading-[1.6] text-[#fff]">
            Contact us to explore customized solutions that meet your needs.
          </p>
        </div>
        <div className="cta-button">
          <a href="/contactus">
            <button className="bg-orange-500 max-[1000px]:mt-[1rem] cursor-pointer text-white px-4 py-2 rounded-md border border-white hover:bg-orange-400">
              Contact Us
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Products1;