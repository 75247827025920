import React from "react";
import purposebg from "../Images/purpose.webp";
import mission from "../Images/mission.webp";
import vision from "../Images/vision.webp";
const Purpose1 = () => {
  const data = [
    {
      id: 1,
      img: mission,
      heading: "Mission",
      content: [
        "Enabling Clean and Green Future.",
        "Partner in Sustainable Development.",
      ],
    },
    {
      id: 2,
      img: vision,
      heading: "Vision",
      content: [
        "Ethics Backed Prosperous Organization.",
        "Bridging the Market Gap.",
      ],
    },
  ];
  return (
    <div className="bg-[#75D1D8] py-[3%] px-[3%]">
      <div className="flex max-[1000px]:flex-col items-center justify-center gap-11 ">
        {/*--first column---*/}
        <div className="w-1/2 max-[1000px]:w-full h-full max-[1000px]:mt-5 ">
          <img src={purposebg} className="w-full " alt="purpose-bg" />
        </div>
        {/*--second column---*/}
        <div className="w-1/2 max-[1000px]:w-full">
          <div className="flex-1">
            <div className="mb-4">
              <p className="text-[1.5rem] font-[500] text-[#10373A]  ad">
                <span className="text-orange-600 font-bold text-[1.4rem]">
                  C
                </span>
                onnecting
                <span className="text-orange-600 font-bold text-[1.4rem]">
                  {" "}
                  U
                </span>
                sers and
                <span className="text-orange-600 font-bold text-[1.4rem]">
                  {" "}
                  S
                </span>
                ervice
                <span className="text-orange-600 font-bold text-[1.4rem]">
                  {" "}
                  P
                </span>
                roviders
                <span className="text-orange-600 font-bold text-[1.4rem]">
                  {" "}
                  (CUSP)
                </span>
              </p>
            </div>
            <p className="text-[16px] font-[400] leading-[1.7rem] text-[#14201B] ">
              Our mission is driven by a simple yet powerful goal: to make the
              transition to renewable energy, particularly solar power, seamless
              and accessible for everyone in India. As we observed the
              fragmented landscape of solar products and services, it became
              clear that there was a pressing need for a unified platform that
              could bring together suppliers, installers, and users. And so,
              CUSP Solar was born.
            </p>
            <br />
            <p className="text-[16px] font-[400] leading-[1.7rem] text-[#14201B]">
              We’re here to support users on their journey towards green energy.
              Whether you’re a homeowner looking to switch to solar or a
              business ready to embrace sustainability, we facilitate
              connections, negotiations, and contracts between users and service
              providers transparently and straightforwardly.
            </p>
            <br />
            <p className="text-[16px] font-[400] leading-[1.7rem] text-[#14201B] ">
              At the heart of what we do is a commitment to creating win-win
              situations for all involved. Our platform empowers users to make
              informed decisions while helping service providers expand their
              reach and impact. By bridging this gap, we’re not just promoting
              solar power; we’re building a future where clean energy is the
              smart, easy choice for everyone.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Purpose1;
